import { BaseApi } from '@src/apis/base'

class RecordApi extends BaseApi {
  async initRecord({ questionSetId }) {
    try {
      const res = await this.client.post(this.endpoints.RECORD.RECORDS, {
        questionSetId
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Get all records
   * @returns {Promise<*>}
   */
  async getRecords() {
    try {
      const res = await this.client.get(this.endpoints.RECORD.RECORDS)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Get record
   * @param id
   * @param questionId
   * @returns {Promise<*>}
   */
  async getRecord({ id, questionId }) {
    try {
      const res = await this.client.get(this.endpoints.RECORD.RECORD(id), {
        params: { questionId }
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Submit answer
   * @param {String} recordId
   * @param {String} sessionId
   * @param {String} combinationId
   * @param {Object} answer
   * @returns {Promise<*>}
   */
  async updateResponse({ recordId, questionId, answer, combinationId }) {
    try {
      const res = await this.client.put(this.endpoints.RECORD.RESPONSES(recordId), {
        questionId,
        combinationId,
        answer
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
  
  async getResponseFile({ id }) {
    try {
      const res = await this.client.get(this.endpoints.RECORD.RESPONSES_FILE(id))
      return res.data
    } catch (e) {
      this.errorHandler(e)
    }
  }
  
}

export { RecordApi }
